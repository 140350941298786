import React from "react";
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import DownloadFileItem from "./DownloadFileItem";

function ManualsSection({ title, description, files }) {
  return (
    <div className="files-section">
      <Fade bottom>
        <h2>{ReactHtmlParser(title)}</h2>
        <p>{ReactHtmlParser(description)}</p>
        <div
          className="files-grid"
          style={files.length === 1 && { gridTemplateColumns: "1fr" }}
        >
          {files.map((item, i) => {
            if(item && ((item.manual_file && item.manual_file.title) || (item.case_study_file&&item.case_study_file.title)) ){
              return(
                <Fade key={i} bottom delay={i * 100}>
                  <DownloadFileItem item={item} />
                </Fade>
              )
            }
          })}
        </div>
      </Fade>
    </div>
  );
}
export default ManualsSection;
