/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { languageContext } from "../templates/MediaCenterTemplate";
import {format,parse,parseISO} from "date-fns"

function DownloadFileItem({
  item: { manual_file = null, case_study_file = null },
}) {
  const lang = React.useContext(languageContext);
  
  const formatDate = date => {
    const parsedDate = parseISO(date)
    return  format(parsedDate,"dd.MM.Y")
  };

  const formatUrlLink = link => {
    let formatLink;
    if (link) {
      formatLink = link.split("//");
      formatLink.splice(1, 0, "//api.");
      formatLink = formatLink.join('');
    }
    return formatLink;
  };

  return manual_file ? (
    <div className="file-item-card">
      <h3>{ReactHtmlParser(manual_file.title)}</h3>
      <div className="file-item-card-info">
        <span>
          {lang === "en_US" ? "Date: " : "Datum: "}
          {ReactHtmlParser(formatDate(manual_file.date))}
        </span>
        <span>{ReactHtmlParser(manual_file.caption)}</span>
      </div>
      <a
        href={formatUrlLink(manual_file.link)}
        className="btn-primary"
        target="_blank"
        download
      >
        download
      </a>
    </div>
  ) : case_study_file ? (
    <div className="file-item-card">
      <h3>{ReactHtmlParser(case_study_file.title)}</h3>
      <div className="file-item-card-info">
        <span>
          {lang === "en_US" ? "Date: " : "Datum: "}
          {ReactHtmlParser(formatDate(case_study_file.date))}
        </span>
        <span>{ReactHtmlParser(case_study_file.caption)}</span>
      </div>
      <a
        href={formatUrlLink(case_study_file.link)}
        className="btn-primary"
        target="_blank"
        download
      >
        download
      </a>
    </div>
  ) : null;
}

export default DownloadFileItem;
