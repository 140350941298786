/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import Facebook from "../../static/assets/facebook.svg";
import Instagram from "../../static/assets/instagram.svg";
import Linkedin from "../../static/assets/linkedin.svg";

function SocialMedia({ data }) {
  // console.log(data);
  return (
    <div className="social-media-section">
      <Fade bottom>
        <h2>{ReactHtmlParser(data.title)}</h2>
        <div className="links-wrapper">
          {data.social_media_links.map(({ link }, i) => (
            <Fade bottom key={i} delay={i * 100}>
              <a href={link.url} target={link.target}>
                <div className="social-media-link">
                  {link.title === "facebook" ? (
                    <Facebook />
                  ) : link.title === "instagram" ? (
                    <Instagram />
                  ) : link.title === "linkedin" ? (
                    <Linkedin />
                  ) : null}
                </div>
              </a>
            </Fade>
          ))}
        </div>
      </Fade>
    </div>
  );
}

export default SocialMedia;
