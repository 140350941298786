/* eslint-disable react/no-array-index-key */
import React from 'react';
import Zoom from 'react-reveal/Zoom';

function Counter({ color, counter }) {
  return (
    <div className="counter" style={{ background: color }}>
      <div className="counter-content">
        {counter?.map((item, index) => (
          <React.Fragment key={index}>
            <Zoom delay={index * 100}>
              <div>
                <h1>{item.description}</h1>
                <p>{item.label}</p>
              </div>
            </Zoom>
          </React.Fragment>
        ))} 
      </div>
    </div>
  );
}

export default Counter;
