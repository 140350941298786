import React from 'react';
import Img from 'gatsby-image';
import PropType from 'prop-types';
import Fade from 'react-reveal/Fade';
import ReactHtmlParser from 'react-html-parser';

const CoverImageTitle = ({ title, fluid }) => (
  <div className="cover-image-wrapper">
    <div className="title-wrapper">
      <Fade bottom>
        <h1 className="title">{ReactHtmlParser(title)}</h1>
      </Fade>
    </div>
    { fluid ? <Img fluid={fluid} style={{ minHeight: '570px', height: '100vh' }} /> : null}
  </div>
);
CoverImageTitle.propTypes = {
  title: PropType.string.isRequired,
  fluid: PropType.shape({
    src: PropType.string,
    srcSet: PropType.string,
  }).isRequired,
};

export default CoverImageTitle;
