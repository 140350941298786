import { graphql } from "gatsby";
import React from "react";
import ManualsSection from "../components/ManualsSection";
import CoverImageTitle from "../components/coverImageTitle";
import Counter from "../components/Counter";
import CoverImage from "../components/coverImage";
import SocialMedia from "../components/SocialMedia";
import ContactForm from "../components/ContactForm";

export const languageContext = React.createContext("de_DE");

function MediaCenterTemplate(props) {
  const {
    data: {
      wordpressPage: {
        acf: {
          title,
          color_template,
          background_image: {
            localFile: {
              childImageSharp: { fluid: coverImage },
            },
          },
          second_main_image: {
            localFile: {
              childImageSharp: { fluid: secondCover },
            },
          },
          counter,
          manuals: {
            title: manualsTitle,
            description: manualsDescription,
            manual_files: manualsFiles,
          },
          case_studies: {
            title: caseStudiesTitle,
            description: caseStudiesDesc,
            case_study_files: caseStudiesFiles,
          },
          social_media
        },
      },
      contactForm,
    },
    pathContext,
  } = props;
  return (
    <languageContext.Provider value={pathContext.lang}>
      <div className="media-center-page">
        <CoverImageTitle title={title} fluid={coverImage} />
        <ManualsSection
          title={manualsTitle}
          description={manualsDescription}
          files={manualsFiles}
        />
        <Counter color={color_template} counter={counter} />
        <ManualsSection
          title={caseStudiesTitle}
          description={caseStudiesDesc}
          files={caseStudiesFiles}
        />
        <SocialMedia data={social_media} />
        <CoverImage fluid={secondCover} />
        <ContactForm
          fluid={secondCover}
          color_template={color_template}
          contactForm={contactForm}
          lang={pathContext.lang}
        />
      </div>
    </languageContext.Provider>
  );
}

export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: { eq: "media-center" }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      path
      yoast_head
      acf {
        title
        color_template
        background_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        second_main_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        counter {
          label
          description
        }
        manuals {
          title
          description
          manual_files {
            manual_file {
              title
              date
              link
              caption
            }
          }
        }
        case_studies {
          title
          description
          case_study_files {
            case_study_file {
              title
              date
              caption
              link
            }
          }
        }
        social_media {
          title
          social_media_links {
            link {
              title
              target
              url
            }
          }
        }
      }
    }
    contactForm: wordpressPage(
      slug: { eq: "contact-form-options" }
      wpml_current_locale: { eq: $lang }
    ) {
      acf {
        color_template
        contact_form_title
        description
        dropdown_label
        dropdown_placeholder
        consent_hint
        consent_checkbox_label
        send_success_message
        dropdown_validation_error_message
        time_window_dropdown_options {
          title
        }
        message_placeholder
        send_button
      }
    }
  }
`;

export default MediaCenterTemplate;
