import React from 'react';
import Img from 'gatsby-image';
import PropType from 'prop-types';
import imageStyles from '../styles/components/singleImageSection.module.css';

const CoverImage = ({ fluid = null, maxHeight }) => fluid && (
<div style={{ maxHeight }} className={imageStyles.imageContainer}>
  <Img fluid={fluid} style={{ maxHeight }} className={imageStyles.image} />
</div>
);

CoverImage.defaultProps = {
  maxHeight: 800,
};

CoverImage.propTypes = {
  fluid: PropType.shape({
    src: PropType.string,
    srcSet: PropType.string,
  }).isRequired,
  maxHeight: PropType.number,
};

export default CoverImage;
